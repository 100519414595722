import { Tooltip } from "react-bootstrap";
import AppalachianLogo from "../../assets/chapters/Appalachian State Logo.jpg"
import ScaleProjectLogo from "../../assets/chapters/The Scale Project Logo.jpg";
import BostonLogo from "../../assets/chapters/FLIP @ BostonU Logo.png";
import BrownLogo from "../../assets/chapters/FirstGen@Brown Logo.jpg";
import CMULogo from "../../assets/chapters/FirstTogether@CMU Logo.jpg";
import CWRULogo from "../../assets/chapters/First CWRU Logo.jpg";
import WoosterLogo from "../../assets/chapters/FSGO - College of Wooster Logo.jpg";
import ColumbiaLogo from "../../assets/chapters/Columbia FLI Network Logo.jpg";
import CornellLogo from "../../assets/chapters/Cornell FGSU Logo.jpg";
import EmoryLogo from "../../assets/chapters/Emory FLIP Logo.jpg";
import FurmanLogo from "../../assets/chapters/Furman FGSA Logo.jpg";
import MasonLogo from "../../assets/chapters/F1rst Gen Mason Logo.jpg";
import MadisonLogo from "../../assets/chapters/Madison First Generation Society Logo.jpg";
import HopkinsLogo from "../../assets/chapters/JHU FLI Logo.jpg";
import LehighLogo from "../../assets/chapters/Lehigh F1RST Logo.png";
import NYULogo from "../../assets/chapters/FLIP at NYU Logo.jpg";
import NortheasternLogo from "../../assets/chapters/Northeastern FGLI-SU Logo.jpg";
import PitzerLogo from "../../assets/chapters/FirstGen Pitzer College Logo.jpg";
import SRJCLogo from "../../assets/chapters/SRJC FLIP Photo.jpg";
import TarletonLogo from "../../assets/chapters/Tarleton F1RST Logo.jpg";
import TXSTLogo from "../../assets/chapters/FLIP at TXST Logo.png";
import UMassLogo from "../../assets/chapters/UMass FLIP Logo.jpg";
import UCLALogo from "../../assets/chapters/AFGU - UCLA Logo.jpg";
import UFloridaLogo from "../../assets/chapters/FGO Logo.jpg";
import MichiganLogo from "../../assets/chapters/FGCS@Michigan Logo.jpg";
import NewHavenLogo from "../../assets/chapters/UNew Haven FGSA Logo.jpg";
import UNCCHLogo from "../../assets/chapters/FGSA at UNC Logo.png";
import NorthTexasLogo from "../../assets/chapters/FGSO UNT Logo.jpg";
import UPennLogo from "../../assets/chapters/PennFirst Logo.jpg";
import UVALogo from "../../assets/chapters/FLIP at UVA Logo.jpg";
import VanderbiltLogo from "../../assets/chapters/FirstVU Logo.png";
import YaleLogo from "../../assets/chapters/YFAM Logo.jpg";



/**
 * Tooltip for Appalachian State
 */
export const AppalachianState = (
    <Tooltip id="tooltip">
        <img src= {AppalachianLogo} alt="FGSA Logo" className="my-2"/>
        First Generation Students Association (FGSA)
    </Tooltip>  
);

/**
 * Tooltip for Bard College
 */
export const BardCollege = (
    <Tooltip id="tooltip">
        <img src= {ScaleProjectLogo} alt="The Scale Project Logo" className="my-2"/>
        The Scale Project 
    </Tooltip>  
);

/**
 * Tooltip for Boston University
 */
export const BostonU = (
    <Tooltip id="tooltip">
        <img src= {BostonLogo} alt="FLIP @ BostonU Logo" className="my-2"/>
        FLIP @ BostonU
    </Tooltip>  
);

/**
 * Tooltip for Brown
 */
export const Brown = (
    <Tooltip id="tooltip">
        <img src= {BrownLogo} alt="First-Gens@Brown Logo" className="my-2"/>
        First-Gens@Brown
    </Tooltip>  
);

/**
 * Tooltip for Carnegie Mellon
 */
export const CarnegieMellon = (
    <Tooltip id="tooltip">
        <img src= {CMULogo} alt="FirstTogether Logo" className="my-2"/>
        FirstTogether
    </Tooltip>  
);

/**
 * Tooltip for CWRU
 */
export const CWRU = (
    <Tooltip id="tooltip">
        <img src= {CWRULogo} alt="First CWRU Logo" className="my-2"/>
        FirstCWRU
    </Tooltip>  
);

/**
 * Tooltip for Wooster
 */
export const Wooster = (
    <Tooltip id="tooltip">
        <img src= {WoosterLogo} alt="FGSO Logo" className="my-2"/>
        First Generation Students Organization (FGSO)
    </Tooltip>  
);

/**
 * Tooltip for Columbia
 */
export const Columbia = (
    <Tooltip id="tooltip">
        <img src= {ColumbiaLogo} alt="Columbia FLI Network Logo" className="my-2"/>
        Columbia FLI Network
    </Tooltip>  
);

/**
 * Tooltip for Cornell
 */
export const Cornell = (
    <Tooltip id="tooltip">
        <img src= {CornellLogo} alt="Cornell FGSU Logo" className="my-2"/>
        Cornell FGSU
    </Tooltip>  
);

/**
 * Tooltip for Emory
 */
export const Emory = (
    <Tooltip id="tooltip">
        <img src= {EmoryLogo} alt="Emory FLIP Logo" className="my-2"/>
        Emory FLIP
    </Tooltip>  
);

/**
 * Tooltip for Furman
 */
export const Furman = (
    <Tooltip id="tooltip">
        <img src= {FurmanLogo} alt="FGSA Logo" className="my-2"/>
        Furman First Generation Student Alliance (FGSA)
    </Tooltip>  
);

/**
 * Tooltip for George Mason
 */
export const GeorgeMason = (
    <Tooltip id="tooltip">
        <img src= {MasonLogo} alt="F1rst Gen @ Mason Logo" className="my-2"/>
        F1rst Gen @ Mason
    </Tooltip>  
);

/**
 * Tooltip for James Madison
 */
export const JamesMadison = (
    <Tooltip id="tooltip">
        <img src= {MadisonLogo} alt="Madison FGS Logo" className="my-2"/>
        Madison First Generation Society
    </Tooltip>  
);

/**
 * Tooltip for Johns Hopkins
 */
export const JohnsHopkins = (
    <Tooltip id="tooltip">
        <img src= {HopkinsLogo} alt="IFJH Logo" className="my-2"/>
        I'm First at Johns Hopkins
    </Tooltip>  
);

/**
 * Tooltip for Lehigh University
 */
export const LehighU = (
    <Tooltip id="tooltip">
        <img src= {LehighLogo} alt="Lehigh F1RST Logo" className="my-2"/>
        Lehigh F1RST
    </Tooltip>  
);

/**
 * Tooltip for NYU
 */
export const NYU = (
    <Tooltip id="tooltip">
        <img src= {NYULogo} alt="FLIP at NYU Logo" className="my-2"/>
        FLIP at NYU
    </Tooltip>  
);

/**
 * Tooltip for Northeastern
 */
export const Northeastern = (
    <Tooltip id="tooltip">
        <img src= {NortheasternLogo} alt="FGLI-SU Logo" className="my-2"/>
        First-Gen Low-Income Student Union (FGLI-SU)
    </Tooltip>  
);

/**
 * Tooltip for Pitzer
 */
export const Pitzer = (
    <Tooltip id="tooltip">
        <img src= {PitzerLogo} alt="PFGC Logo" className="my-2"/>
        Pitzer First-Gen Club
    </Tooltip>  
);

/**
 * Tooltip for SRJC
 */
export const SRJC = (
    <Tooltip id="tooltip">
        <img src= {SRJCLogo} alt="SRJC FLIP Logo" className="my-2"/>
        SRJC FLIP
    </Tooltip>  
);

/**
 * Tooltip for Tarleton State
 */
export const TarletonState = (
    <Tooltip id="tooltip">
        <img src= {TarletonLogo} alt="Tarleton F1RST Logo" className="my-2"/>
        Tarleton F1RST
    </Tooltip>  
);

/**
 * Tooltip for Texas State
 */
export const TexasState = (
    <Tooltip id="tooltip">
        <img src= {TXSTLogo} alt="FLIP at TXST Logo" className="my-2"/>
        FLIP at TXST
    </Tooltip>  
);

/**
 * Tooltip for UMass Amherst
 */
export const UMassAmherst = (
    <Tooltip id="tooltip">
        <img src= {UMassLogo} alt="UMass FLIP Logo" className="my-2"/>
        UMass FLIP
    </Tooltip>  
);

/**
 * Tooltip for UCLA
 */
export const UCLA = (
    <Tooltip id="tooltip">
        <img src= {UCLALogo} alt="AFGU Logo" className="my-2"/>
        Association of First Generation Undergraduates (AFGU)
    </Tooltip>  
);

/**
 * Tooltip for University of Florida
 */
export const UFlorida = (
    <Tooltip id="tooltip">
        <img src= {UFloridaLogo} alt="FGO Logo" className="my-2"/>
        First Generation Organization (FGO)
    </Tooltip>  
);

/**
 * Tooltip for University of Michigan
 */
export const UMichigan = (
    <Tooltip id="tooltip">
        <img src= {MichiganLogo} alt="FGCS@Michigan Logo" className="my-2"/>
        FGCS@Michigan
    </Tooltip>  
);

/**
 * Tooltip for University of New Haven
 */
export const UNewHaven = (
    <Tooltip id="tooltip">
        <img src= {NewHavenLogo} alt="FGSA Logo" className="my-2"/>
        UNew Haven FGSA
    </Tooltip>  
);

/**
 * Tooltip for UNCCH
 */
export const UNCCH = (
    <Tooltip id="tooltip">
        <img src= {UNCCHLogo} alt="FGSA Logo" className="my-2"/>
        First Generation Student Association (FGSA)
    </Tooltip>  
);

/**
 * Tooltip for UNT
 */
export const UNT = (
    <Tooltip id="tooltip">
        <img src= {NorthTexasLogo} alt="FGSO Logo" className="my-2"/>
        UNT First Generation Student Organization (FGSO)
    </Tooltip>  
);

/**
 * Tooltip for University of Pennsylvania
 */
export const UPennsylvania = (
    <Tooltip id="tooltip">
        <img src= {UPennLogo} alt="Penn First Logo" className="my-2"/>
        Penn First
    </Tooltip>  
);

/**
 * Tooltip for University of Virginia
 */
export const UVirginia = (
    <Tooltip id="tooltip">
        <img src= {UVALogo} alt="FLIP at UVA Logo" className="my-2"/>
        FLIP at UVA
    </Tooltip>  
);

/**
 * Tooltip for University of Vanderbilt
 */
export const UVanderbilt = (
    <Tooltip id="tooltip">
        <img src= {VanderbiltLogo} alt="FirstVU Logo" className="my-2"/>
        FirstVU
    </Tooltip>  
);

/**
 * Tooltip for Yale
 */
export const Yale = (
    <Tooltip id="tooltip">
        <img src= {YaleLogo} alt="YFAM Logo" className="my-2"/>
        Yale FGLI Advocacy Movement (YFAM)
    </Tooltip>  
);