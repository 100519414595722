import React from "react";
import Image from "./media/Image";
import Instagram from "../assets/chapterSocials/instagram";
import Twitter from "../assets/chapterSocials/twitter";
import Facebook from "../assets/chapterSocials/facebook";
import ColoredButton from "./core/ColoredButton";

/**
 * ifInsta props:
 */
interface ifInstaProps {
  instagram: string
}

/**
 * If the chapter has an instagram, component displays it
 * @param instagram- instagram profile
 */
const IfInsta: React.FC<ifInstaProps> = ({
  instagram,
}) => {
  if (instagram !== ""){
    return (
      <a href={instagram} aria-label="Instagram" target = "_blank" rel="noreferrer"><Instagram/></a>
    )
  }
  else {
    return (
      <div></div>
    )
  }
};

/**
 * ifTwitter props:
 */
interface ifTwitterProps {
  twitter: string
}

/**
 * If the chapter has a twitter account, component displays it
 * @param twitter- twitter profile
 */
const IfTwitter: React.FC<ifTwitterProps> = ({
  twitter,
}) => {
  if (twitter !== ""){
    return (
      <a href={twitter} aria-label="Twitter" target = "_new"><Twitter/></a>
    )
  }
  else {
    return (
      <div></div>
    )
  }
};

/**
 * ifFacebook props:
 */
interface ifFacebookProps {
  facebook: string
}

/**
 * If the chapter has a facebook account, component displays it
 * @param facebook- facebook profile
 */
const IfFacebook: React.FC<ifFacebookProps> = ({
  facebook,
}) => {
  if (facebook !== ""){
    return (
      <a href={facebook} aria-label="Facebook" target = "_new"><Facebook/></a>
    )
  }
  else {
    return (
      <div></div>
    )
  }
};

/**
 * Chapter props:
 */
interface chapterProps {
    schoolName: string,
    chapterName: string,
    chapterEmail: string,
    instagram: string
    twitter: string
    facebook: string
    chapterLogo: string,
    logoAlt: string,
    initiatives: string,
    impact: string,
}

/**
 * Chapter component:
 * This component is a helper method for the chapters accordian. It details a single accordian
 * item which can be used for every chapter
 * @param src - path that accordian item is being linked to
 * @param collegeName - Name of the college
 * @param chapterName - Name of the chapter
 * @param chapterEmail- Email of the chapter
 * @param instagram- Instagram handle of the chapter
 * @param twitter- Twitter handle of the chapter (no, we will not be calling it X)
 * @param facebook- Facebook handle of the chapter
 * @param chapterLogo- Logo for the chapter
 * @param logoAlt- Alternatie text for the chapter logo
 * @param initiatives- Chapter's initiatives
 * @param impact- Chapter's impact
 */
const Chapter: React.FC<chapterProps> = (props) => {
  return (
    <div className="mx-8 mt-5">
      <ColoredButton
        title={"⇦ Back to Map"}
        to={"/chapters"}
        target={""}
        className={"w-40 text-white py-2 px-4 rounded-lg transition duration-300 ease-in-out hover:bg-theme_skyBlue hover:animate-pulse bg-theme_purple transition-colors"}>
      </ColoredButton>
      <div className="flex flex-col items-center mt-3">
        <Image src={props.chapterLogo} alt={props.logoAlt} className="w-full max-w-lg px-4 py-4"/>
        <h2>{props.schoolName}</h2>
        <h3>Initiatives</h3>
        <p>{props.initiatives}</p>
        <h3>Impact</h3>
        <p>{props.impact}</p>
        <p>{props.chapterEmail}</p>
      </div>
      
      {/** Chapter Socials */}
      <div className="flex flex-row flex-wrap align items-center justify-center space-between mx-10" role="navigation">
        <IfInsta instagram={props.instagram}></IfInsta>
        <IfTwitter twitter = {props.twitter}></IfTwitter>
        <IfFacebook facebook= {props.facebook}></IfFacebook>
      </div>
    </div>  
  );
};

export default Chapter;