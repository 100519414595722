import React from "react";
import { PageWrapper } from "../components/page_sections/PageWrapper";
import PageTitle from "../components/core/PageTitle";
import { StyledPageSection } from "../components/page_sections/StyledPageSection";
//import styled from "styled-components";
import Obama from "../assets/Obama.png";
import lehighStudents from "../assets/lehighStudents.jpg"
import Image from "../components/media/Image"
import useScreenType from "../utils/useScreenType";

// Placeholder for news articles
const articles = [
  {
    src: Obama,
    alt: "FLIP National Co-founder, Mandeep Singh talking about FLIP National with President Obama at the first Obama Foundation Summit",
    title: "FLIP National Meets President Obama",
    date: "November 1st, 2017",
    summary: "FLIP National Co-founder, Mandeep Singh talking about FLIP National with President Obama at the first Obama Foundation Summit",
    image: "../assets/lehighStudents.jpg"
  },
  {
    src: lehighStudents,
    alt: "FLIP National members from Lehigh posing for a picture",
    title: "New Partnership with Local Colleges",
    date: "June 30, 2024",
    summary: "FLIP National has partnered with several local colleges to provide more resources and support for students.",
  }
];

/** Headline Article */
const headlineArticle = articles[0];

/**
 * Article props:
 */
type articleProps = {
  src: string,
  alt: string,
  title: string,
  date: string,
  summary: string,
};

/**
 * Handles displaying the Headline article
 * @param src- Path or url for the article's image
 * @param alt- Alt text for the image
 * @param title- Title of the article
 * @param date- Date the article was written
 * @param summary- A summary of the article
 */
const HeadlineArticle: React.FC<articleProps> = (props) => {
  return (
    <article className="flex flex-row gap-5 p-5 bg-theme_skyBlue my-5">
      <Image src={props.src} alt={props.alt} className="object-contain w-5/12 rounded-lg transition duration-300 ease hover:scale-105"></Image>
      <div className="flex flex-col justify-center w-2/3 ml-12">
        <h2 className="text-3xl">{props.title}</h2>
        <h3 className="text-base text-stone-600">{props.date}</h3>
        <p className="mt-2.5 text-lg text-stone-900">{props.summary}</p>
      </div>
    </article>
  );
};

/**
 * Handles displaying an Article
 * @param src- Path or url for the article's image
 * @param alt- Alt text for the image
 * @param title- Title of the article
 * @param date- Date the article was written
 * @param summary- A summary of the article
 */
const Article: React.FC<articleProps> = (props) => {
  return (
    <article className="flex flex-row gap-5 border border-solid border-sky-50 p-5 bg-theme_lightGray rounded-lg">
      <img src={props.src} alt={props.alt}
      className="w-2/3 rounded-lg transition duration-300 ease hover:scale-105"
      style={{
        height: "auto",
        maxHeight: "100%",
        objectFit: "contain",
      }} //Note: This only didn't stretch with css styling, not tailwind styling, if making more concise leave it
      ></img>
      <div className="flex flex-col w-2/3 justify-center">
        <h2 className="text-2xl">{props.title}</h2>
        <h3 className="text-sm text-stone-600">{props.date}</h3>
        <p className="mt-2.5 text-base text-stone-900">{props.summary}</p>
      </div>
    </article>
  );
};

/**
 * Handles displaying the Headline article on mobile
 * @param src- Path or url for the article's image
 * @param alt- Alt text for the image
 * @param title- Title of the article
 * @param date- Date the article was written
 * @param summary- A summary of the article
 */
const HeadlineArticleMobile: React.FC<articleProps> = (props) => {
  return (
    <article className="flex flex-col justify-center gap-5 p-5 bg-theme_skyBlue my-5">
      <Image src={props.src} alt={props.alt} className="justify-self-center self-center max-w-96 rounded-lg transition duration-300 ease hover:scale-105"></Image>
      <div className="flex flex-col justify-center ml-12">
        <h2 className="text-3xl">{props.title}</h2>
        <h3 className="text-base text-stone-600">{props.date}</h3>
        <p className="mt-2.5 text-lg text-stone-900">{props.summary}</p>
      </div>
    </article>
  );
};

/**
 * Handles displaying an Article on mobile
 * @param src- Path or url for the article's image
 * @param alt- Alt text for the image
 * @param title- Title of the article
 * @param date- Date the article was written
 * @param summary- A summary of the article
 */
const ArticleMobile: React.FC<articleProps> = (props) => {
  return (
    <article className="flex flex-col justify-center gap-5 p-5 bg-theme_lightGray my-5">
      <Image src={props.src} alt={props.alt} className="justify-self-center self-center max-w-96 rounded-lg transition duration-300 ease hover:scale-105"></Image>
      <div className="flex flex-col justify-center ml-12">
        <h2 className="text-2xl">{props.title}</h2>
        <h3 className="text-sm text-stone-600">{props.date}</h3>
        <p className="mt-2.5 text-base text-stone-900">{props.summary}</p>
      </div>
    </article>
  );
};


/**
 * News page: Webpage for FLIP National related news and updates
 */
const News: React.FC = () => {
  document.title = "News";
  const screenType = useScreenType();
  if (screenType === "phone") {
    return (
      <PageWrapper>
        <StyledPageSection background_color="rgb(255, 255, 255)">
          <PageTitle text="News" />
        </StyledPageSection>
        <HeadlineArticleMobile
          src={headlineArticle.src}
          alt={headlineArticle.alt}
          title={headlineArticle.title}
          date={headlineArticle.date}
          summary={headlineArticle.summary}>
        </HeadlineArticleMobile>
        {articles.slice(1).map((article) => (
          <div className="flex flex-col gap-5 p-5">
            <ArticleMobile
              src={article.src}
              alt={article.alt}
              title={article.title}
              date={article.date}
              summary={article.summary}>
            </ArticleMobile>
          </div>
        ))}
      </PageWrapper>
    );
  }
  else {
    return (
      <PageWrapper>
        <StyledPageSection background_color="rgb(255, 255, 255)">
          <PageTitle text="News" />
        </StyledPageSection>
        <HeadlineArticle
          src={headlineArticle.src}
          alt={headlineArticle.alt}
          title={headlineArticle.title}
          date={headlineArticle.date}
          summary={headlineArticle.summary}>
        </HeadlineArticle>
        {articles.slice(1).map((article) => (
          <div className="flex flex-col gap-5 p-5">
            <Article
              src={article.src}
              alt={article.alt}
              title={article.title}
              date={article.date}
              summary={article.summary}>
            </Article>
          </div>
        ))}
      </PageWrapper>
    )
  }
};

export default News;
