import React from "react";
import { PageWrapper } from "../components/page_sections/PageWrapper";
import PageTitle from "../components/core/PageTitle";
import { StyledPageSection } from "../components/page_sections/StyledPageSection";
import FLIPSideLogo from "../assets/TheFLIPSideLogo.jpg";
import Image from "../components/media/Image";
import { ScalableHeading2} from "../components/core/ScalableText";
import AudioPlayer from "../components/media/AudioPlayer";
import { episodes } from "../utils/podcastInfo";
import Spotify from "../assets/podcast/Spotify_icon";
import Apple from "../assets/podcast/ApplePodcasts_icon";
import Google from "../assets/podcast/GooglePodcasts_icon";
import Amazon from "../assets/podcast/AmazonMusic_icon";
import IHeartRadio from "../assets/podcast/IHeartRadio_icon";

/**
 * Podcast page:
 * Webpage to host the Flipside Podcast
 */
const Podcast: React.FC = () => {
  document.title = "Podcast";
  return (
    <PageWrapper>
      <StyledPageSection section_aria_label= "The FLIP Side" background_color="rgb(255, 255, 255)">
        <PageTitle text="The FLIP Side" />
      </StyledPageSection>
      <main id="main">
        {/* Using a grid here to make it so that there are two even columns, can use order to determine which goes first either the image container or the text container. */}
      <section className="mt-10 w-full max-w-6xl mx-auto">
        <div className="relative grid grid-cols-1 md:grid-cols-2 gap-8 mx-8">
          <div className="flex flex-col justify-center items-center">
            <Image src={FLIPSideLogo} alt={"The FLIP Side Logo"} className="w-1/2 md:w-full "/>
          </div>
          
          <div className="flex flex-col justify-center items-center text-center">
            <ScalableHeading2 className="" text={"The FLIP Side"}></ScalableHeading2>
            <p className="">The FLIP Side is a podcast by and for first-generation and/or low-income (FGLI) college students, and is dedicated to telling the stories & narratives of FGLI students across the country, as well as sharing insight & advice about various issues that FGLI students face in higher education from the student perspective.</p>
          </div>
        </div>
        </section>
        <div className="self-center mx-10 my-10 object-scale-down rounded-xl shadow-md fill-theme_purple bg-theme_blue" >
        <br></br>
        <AudioPlayer tracks={episodes}></AudioPlayer>
        <br></br>
        </div>
        <h3 className="mx-10">Interested in hearing more?</h3>
        <p className="mx-10" >Click below to listen to The FLIP Side on your favorite podcasting app</p>
        <div className="flex flex-row flex-wrap align items-center justify-center space-between mx-10">
          <a href="https://lnkd.in/eMNrjnTR"><Spotify/></a>
          <div className = "min-w-10 min-h-10"></div>
          <a href="https://lnkd.in/eqjDSj8v"><Apple/></a>
          <div className = "min-w-10 min-h-10"></div>
          <a href="https://lnkd.in/e_qcFhhZ"><Google/></a>
          <div className = "min-w-10 min-h-10"></div>
          <a href="https://lnkd.in/eVgv6uVH"><Amazon/></a>
          <div className = "min-w-10 min-h-10"></div>
          <a href=" https://lnkd.in/eyt6_82t"><IHeartRadio/></a>
        </div>
        <div className = "min-w-10 min-h-10"></div>
      </main>
    </PageWrapper>
  );
};

export default Podcast