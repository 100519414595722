import ChapterMarkers from "./ChapterMarkers"
import UsMap from "./UsMap";


const FlipMap = (
    <div id="map" className="p-5">
        <svg viewBox="0 0 958 602"
            preserveAspectRatio="xMinYMin meet"
            aria-label= "FLIP National Map of Chapters">
            <UsMap></UsMap>
            {ChapterMarkers}
        </svg>
    </div>
);

export default FlipMap
