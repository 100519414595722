import React, {useState} from 'react'
import Form from 'react-bootstrap/Form';
import { PageWrapper } from "../components/page_sections/PageWrapper";
import ChaptersAccordian from "../components/ChaptersAccordian";
import PageTitle from "../components/core/PageTitle";
import { StyledPageSection } from "../components/page_sections/StyledPageSection";
import Offcanvas from 'react-bootstrap/Offcanvas';
import useScreenType from "../utils/useScreenType";
import SearchIcon from '../assets/Search_Icon';
import { purpleButton } from '../components/core/ColoredButton';
import FindChapter from '../components/FindChapter';
import FlipMap from '../components/map/FlipMap';



/**
 * Contact Chapter Team Component:
 * A form used to contact a FLIP National Chapter Establishment & Development Team
 */
const ContactChapterTeam = () => {
  /** Validates that email was sent */
  const [validated, setValidated] = useState(false);

  /** Handles Submit event */
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
  };

  return (
    <div className="mx-8">
      <div className="max-w-5xl mx-auto my-10 p-6 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold mb-2">Don't see your school?</h2>
        <p className="text-left text-gray-700 mb-6">
          If you're interested in starting a FLIP National chapter at your school, please
          email chapestdev@flipnational.org or reach out with the form below.
        </p>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="validationCustom01">
            <Form.Label>Name</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Name"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Email Address"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid email address.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group
            controlId="exampleForm.ControlTextarea1"
            className="mb-3"
          >
            <Form.Label>Message</Form.Label>
            <Form.Control as="textarea" rows={3} placeholder="Leave a Message" required/>
          </Form.Group>
          <button type= "submit" className = {purpleButton} >
            Submit
          </button>
        </Form>
      </div>
    </div>
  );
}

/**
 * SideBar component:
 * This component handles navigation on mobile. It will be used to navigate between pages.
 */
function SideBar({ name, ...props }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const toggleShow = () => setShow((s) => !s);
  
  return (
    <div id="findChapter">
      <div onKeyDown={toggleShow} className="flex flex-row justify-between">
        <div style={{width: "82%"}}>
          {FlipMap}
        </div>
        <button
          onClick={toggleShow}
          onKeyDown={toggleShow}
          className={"max-w-auto h-25 justify-top text-white py-2 px-4 mt-2 rounded-lg transition duration-300 ease-in-out hover:bg-theme_skyBlue bg-theme_blue transition-colors"}>
            <div className="flex flex-row">
              <SearchIcon/><div className='ml-2'>Find Chapter</div>
            </div>
        </button>
      </div>
      <div id="findChapter">
        <Offcanvas show={show} onHide={handleClose} {...props} autofocus>
          <Offcanvas.Header closeButton className="place-self-end" closeLabel="Close">
          </Offcanvas.Header>
          <Offcanvas.Body>
            <FindChapter></FindChapter>
            <ChaptersAccordian></ChaptersAccordian>
          </Offcanvas.Body>
      </Offcanvas>
      </div>
    </div>
  );
}

/**
 * 
 */
function ChaptersSidebar() {
  return (
    <>
      {['end'].map((placement, idx) => (
        <SideBar key={idx} placement={placement} name={placement} />
      ))}
    </>
  );
}


/**
 * University Chapters page:
 * Landing page for all of FLIP National's chapters, including an interactive map
 */
const UniChapters: React.FC = () => {
  const screenType = useScreenType();
  if (screenType === "phone" || screenType === "tablet"){
    return (
      <PageWrapper>
        <StyledPageSection section_aria_label= "Page title banner" background_color="rgb(255, 255, 255)">
          <PageTitle text="Our Chapters" />
        </StyledPageSection>
        <main>
          {FlipMap}
          <FindChapter></FindChapter>
          <ChaptersAccordian/>
          <ContactChapterTeam></ContactChapterTeam>
        </main>
      </PageWrapper>
    );
  }
  else return (
    <PageWrapper>
      <StyledPageSection section_aria_label= "page title" background_color="rgb(255, 255, 255)">
        <PageTitle text="Our Chapters" />
      </StyledPageSection>
      <main id="main" className="my-4 mx-4">
        <ChaptersSidebar></ChaptersSidebar>
        <ContactChapterTeam></ContactChapterTeam>
      </main>
    </PageWrapper>
  );
};



export default UniChapters;