import { OverlayTrigger} from "react-bootstrap";
import { ColorPalette } from "../../utils/themes";
import {AppalachianState, BardCollege, BostonU, Brown, CarnegieMellon,
    Columbia, Cornell, CWRU, Emory, Furman, GeorgeMason, JamesMadison,
    JohnsHopkins, LehighU, Northeastern, NYU, Pitzer, SRJC, TarletonState,
    TexasState, UCLA, UFlorida, UMassAmherst, UMichigan, UNCCH, UNewHaven,
    UNT, UPennsylvania, UVanderbilt, UVirginia, Wooster, Yale}
    from "./ChapterTooltips";

/**
 * Displays the chapter markers with tooltips for each FLIP National chapter
 */
const ChapterMarkers = (
    <svg aria-label= "Chapters">
        <OverlayTrigger placement="top" overlay={AppalachianState}>
            <a aria-label="First Generation Students Association (FGSA)" href="/AppalachianState" target="_blank" title = {"First Generation Students Association (FGSA)"}>
                <circle cx="78.5%" cy="56.5%" r={5} fill={ColorPalette.purple}/>
            </a>
        </OverlayTrigger>
        <OverlayTrigger placement="top" overlay={BardCollege}>
            <a aria-label="The Scale Project" href="/BardCollege" target="_blank">
                <circle cx="90.3%" cy="30%" r={5} fill={ColorPalette.purple}/>
            </a>
        </OverlayTrigger>
        <OverlayTrigger placement="top" overlay={BostonU}>
            <a aria-label="FLIP @ BostonU" href="/BostonU" target="_blank">
                <circle cx="94.5%" cy="26.2%" r={5} fill={ColorPalette.purple}/>
            </a>
        </OverlayTrigger>
        <OverlayTrigger placement="top" overlay={Brown}>
            <a aria-label="First-Gens@Brown" href="/Brown" target="_blank">
                <circle cx="95%" cy="28.5%" r={5} fill={ColorPalette.purple}/>
            </a>
        </OverlayTrigger>
        <OverlayTrigger placement="top" overlay={CarnegieMellon}>
            <a aria-label="FirstTogether" href="/CarnegieMellon" target="_blank">
                <circle cx="80.5%" cy="38.5%" r={5} fill={ColorPalette.purple}/>
            </a>
        </OverlayTrigger>
        <OverlayTrigger placement="top" overlay={CWRU}>
            <a aria-label="FirstCWRU" href="/CWRU" target="_blank">
                <circle cx="77%" cy="36%" r={5} fill={ColorPalette.purple}/>
            </a>
        </OverlayTrigger>
        <OverlayTrigger placement="top" overlay={Wooster}>
            <a aria-label="First Generation Students Organization (FGSO)" href="/Wooster" target="_blank">
                <circle cx="76.5%" cy="38%" r={5} fill={ColorPalette.purple}/>
            </a>
        </OverlayTrigger>
        <OverlayTrigger placement="top" overlay={Columbia}>
            <a aria-label="Columbia FLI Network" href="/Columbia" target="_blank">
                <circle cx="91.4%" cy="32.5%" r={5} fill={ColorPalette.purple}/>
            </a>
        </OverlayTrigger>
        <OverlayTrigger placement="top" overlay={Cornell}>
            <a aria-label="Cornell FGSU" href="/Cornell" target="_blank">
                <circle cx="86%" cy="29.5%" r={5} fill={ColorPalette.purple}/>
            </a>
        </OverlayTrigger>
        <OverlayTrigger placement="top" overlay={Emory}>
            <a aria-label="Emory FLIP" href="/Emory" target="_blank">
                <circle cx="74%" cy="65.5%" r={5} fill={ColorPalette.purple}/>
            </a>
        </OverlayTrigger>
        <OverlayTrigger placement="top" overlay={Furman}>
            <a aria-label="Furman First Generation Student Alliance (FGSA)" href="/Furman" target="_blank">
                <circle cx="78%" cy="61%" r={5} fill={ColorPalette.purple}/>
            </a>
        </OverlayTrigger>
        <OverlayTrigger placement="top" overlay={GeorgeMason}>
            <a aria-label="F1rst Gen @ Mason" href="/GeorgeMason" target="_blank">
                <circle cx="85.5%" cy="43.5%" r={5} fill={ColorPalette.purple}/>
            </a>
        </OverlayTrigger>
        <OverlayTrigger placement="top" overlay={JamesMadison}>
            <a aria-label="Madison First Generation Society" href="/JamesMadison" target="_blank">
                <circle cx="83.3%" cy="46.5%" r={5} fill={ColorPalette.purple}/>
            </a>
        </OverlayTrigger>
        <OverlayTrigger placement="top" overlay={JohnsHopkins}>
            <a aria-label="I'm First at Johns Hopkins" href="/JohnsHopkins" target="_blank">
                <circle cx="86.5%" cy="40.5%" r={5} fill={ColorPalette.purple}/>
            </a>
        </OverlayTrigger>
        <OverlayTrigger placement="top" overlay={LehighU}>
            <a aria-label="Lehigh F1RST" href="/LehighU" target="_blank">
                <circle cx="88.3%" cy="36%" r={5} fill={ColorPalette.purple}/>
            </a>
        </OverlayTrigger>
        <OverlayTrigger placement="top" overlay={NYU}>
            <a aria-label="FLIP at NYU" href="/NYU" target="_blank">
                <circle cx="91%" cy="33%" r={5} fill={ColorPalette.purple}/>
            </a>
        </OverlayTrigger>
        <OverlayTrigger placement="top" overlay={Northeastern}>
            <a aria-label="First-Gen Low-Income Student Union (FGLI-SU)" href="/Northeastern" target="_blank">
                <circle cx="95%" cy="25.8%" r={5} fill={ColorPalette.purple}/>
            </a>
        </OverlayTrigger>
        <OverlayTrigger placement="top" overlay={Pitzer}>
            <a aria-label="Pitzer First-Gen Club" href="/Pitzer" target="_blank">
                <circle cx="9.5%" cy="60%" r={5} fill={ColorPalette.purple}/>
            </a>
        </OverlayTrigger>
        <OverlayTrigger placement="top" overlay={SRJC}>
            <a aria-label="SRJC FLIP" href="/SRJC" target="_blank">
                <circle cx="2.5%" cy="38%" r={5} fill={ColorPalette.purple}/>
            </a>
        </OverlayTrigger>
        <OverlayTrigger placement="top" overlay={TarletonState}>
            <a aria-label="Tarleton F1RST" href="/TarletonState" target="_blank">
                <circle cx="46.5%" cy="73%" r={5} fill={ColorPalette.purple}/>
            </a>
        </OverlayTrigger>
        <OverlayTrigger placement="top" overlay={TexasState}>
            <a aria-label="FLIP at TXST" href="/TexasState" target="_blank">
                <circle cx="46.7%" cy="84%" r={5} fill={ColorPalette.purple}/>
            </a>
        </OverlayTrigger>
        <OverlayTrigger placement="top" overlay={UMassAmherst}>
            <a aria-label="UMass FLIP" href="/UMassAmherst" target="_blank">
                <circle cx="92%"cy="27%" r={5} fill={ColorPalette.purple}/>
            </a>
        </OverlayTrigger>
        <OverlayTrigger placement="top" overlay={UCLA}>
            <a aria-label="Association of First Generation Undergraduates (AFGU)" href="/UCLA" target="_blank">
                <circle cx="7.5%" cy="58%" r={5} fill={ColorPalette.purple}/>
            </a>
        </OverlayTrigger>
        <OverlayTrigger placement="top" overlay={UFlorida}>
            <a aria-label="First Generation Organization (FGO)" href="/UFlorida" target="_blank">
                <circle cx="79.5%" cy="80.5%" r={5} fill={ColorPalette.purple}/>
            </a>
        </OverlayTrigger>
        <OverlayTrigger placement="top" overlay={UMichigan}>
            <a aria-label="FGCS@Michigan" href="/UMichigan" target="_blank">
                <circle cx="72.7%" cy="33%" r={5} fill={ColorPalette.purple}/>
            </a>
        </OverlayTrigger>
        <OverlayTrigger placement="top" overlay={UNewHaven}>
            <a aria-label="UNew Haven FGSA" href="/UNewHaven" target="_blank">
                <circle cx="92%" cy="30.5%" r={5} fill={ColorPalette.purple}/>
            </a>
        </OverlayTrigger>
        <OverlayTrigger placement="top" overlay={UNCCH}>
            <a aria-label="First Generation Student Association (FGSA)" href="/UNCCH" target="_blank">
                <circle cx="83%" cy="55%" r={5} fill={ColorPalette.purple}/>
            </a>
        </OverlayTrigger>
        <OverlayTrigger placement="top" overlay={UNT}>
            <a aria-label="UNT First Generation Student Organization (FGSO)" href="/UNT" target="_blank">
                <circle cx="49%" cy="71%" r={5} fill={ColorPalette.purple}/>
            </a>
        </OverlayTrigger>
        <OverlayTrigger placement="top" overlay={UPennsylvania}>
            <a aria-label="Penn First" href="/UPennsylvania" target="_blank">
                <circle cx="88.5%" cy="37.5%" r={5} fill={ColorPalette.purple}/>
            </a>
        </OverlayTrigger>
        <OverlayTrigger placement="top" overlay={UVirginia}>
            <a aria-label="FLIP at UVA" href="/UVirginia" target="_blank">
                <circle cx="84%" cy="47.5%" r={5} fill={ColorPalette.purple}/>
            </a>
        </OverlayTrigger>
        <OverlayTrigger placement="top" overlay={UVanderbilt}>
            <a aria-label="FirstVU" href="/UVanderbilt" target="_blank">
                <circle cx="69%" cy="58%" r={5} fill={ColorPalette.purple}/>
            </a>
        </OverlayTrigger>
        <OverlayTrigger placement="top" overlay={Yale}>
            <a aria-label="Yale FGLI Advocacy Movement (YFAM)" href="/Yale" target="_blank">
                <circle cx="92.6%" cy="30.5%" r={5} fill={ColorPalette.purple}/>
            </a>
        </OverlayTrigger>
    </svg>
);

export default ChapterMarkers